import React from 'react';

function About() {
    const birthday: Date = new Date("1999-12-30");
    const age = Math.floor((new Date().getTime() - birthday.getTime()) / (1000 * 60 * 60 * 24 * 365.25));
    return (
        <section id={"about"}>
            <div className={"about-container"}>
                <div className={"about-title"}>About Me</div>
                <p className={"about-description"}>
                    I am a {age} year old developer living in Switzerland,
                    balancing life as a computer science student and aspiring software engineer.
                    My journey into the realm of technology began with a curiosity sparked by the endless possibilities
                    of programming.
                    This curiosity quickly turned into a passion for developing innovative solutions and tackling
                    complex problems.
                    Studying computer science has not only sharpened my technical skills but also deepened my
                    appreciation for the art of coding.
                </p>
            </div>
            <div className={"skills-container"}>
                <div className={"skills-heading"}>Skills</div>
                <div className={"skills-wrapper"}>
                    <span>React</span>
                    <span>Angular</span>
                    <span>TypeScript</span>
                    <span>JavaScript</span>
                    <span>SASS</span>
                    <span>Styled Components</span>
                    <span>C#</span>
                    <span>SQL</span>
                    <span>Material-UI</span>
                    <span>Jira</span>
                    <span>Android SDK</span>
                    <span>REST</span>
                    <span>Kotlin</span>
                    <span>CI/CD</span>
                    <span>Terraform</span>
                    <span>Helm</span>
                    <span>Kubernetes</span>
                    <span>Python</span>
                    <span>Keras</span>
                    <span>Pytorch</span>
                    <span>TensorFlow</span>
                    <span>NVIDIA Cuda</span>
                    <span>Scrum</span>
                </div>
            </div>
            <div className={"about-experience-section"}>
                <div className={"about-experience-heading"}>Education</div>
                <div className={"about-experience-item"}>
                    <div className={"about-experience-date"}>Sep 2021 - Aug 2025</div>
                    <div>
                        <h2 className={"about-experience-title"}>OST Eastern Switzerland University of Applied Sciences
                            (formerly known as HSR) • <i>BSc Computer Science</i></h2>
                        <p className={"about-experience-description"}>
                            I am currently studying computer science at OST University, where I
                            am learning theoretical and practical aspects of software engineering.

                            The curriculum includes subjects such as algorithms and data structures, object-oriented
                            programming, and software engineering practice.

                            The program also covers advanced topics like artificial intelligence,
                            deep learning, parallel programming and computer vision.

                            I am gaining valuable experience in working with various programming languages and tools
                            such as Java, Python, C++, and TensorFlow.

                            I am also exercising software development methodologies such as Scrum and Agile, which
                            are essential for working in a team environment.
                        </p>
                        {/*<div className={"about-experience-technologies"}>*/}
                        {/*    <span className={"about-experience-technology"}>Cyber Security</span>*/}
                        {/*    <span className={"about-experience-technology"}>Object Oriented Programming</span>*/}
                        {/*    <span className={"about-experience-technology"}>Parallel Programming</span>*/}
                        {/*    <span className={"about-experience-technology"}>Computer Networks</span>*/}
                        {/*    <span className={"about-experience-technology"}>Digital Encodings</span>*/}

                        {/*    <span className={"about-experience-technology"}>AI Foundations & Applications</span>*/}
                        {/*    <span className={"about-experience-technology"}>Image Processing</span>*/}
                        {/*    <span className={"about-experience-technology"}>Computer Vision</span>*/}
                        {/*    <span className={"about-experience-technology"}>Deep Learning</span>*/}

                        {/*    <span className={"about-experience-technology"}>Analysis</span>*/}
                        {/*    <span className={"about-experience-technology"}>Theoretical Computer Science</span>*/}
                        {/*    <span className={"about-experience-technology"}>Probability</span>*/}
                        {/*    <span className={"about-experience-technology"}>Linear Algebra</span>*/}
                        {/*    <span className={"about-experience-technology"}>Statistics</span>*/}
                        {/*    <span className={"about-experience-technology"}>Discrete Mathematics</span>*/}
                        {/*    <span className={"about-experience-technology"}>Mathematical Foundations for Machine Learning</span>*/}

                        {/*    <span className={"about-experience-technology"}>Operating Systems</span>*/}
                        {/*    <span className={"about-experience-technology"}>Algorithms and Datastructures</span>*/}
                        {/*    <span className={"about-experience-technology"}>Databases</span>*/}
                        {/*    <span className={"about-experience-technology"}>Data Engineering</span>*/}
                        {/*    <span className={"about-experience-technology"}>Web Engineering</span>*/}
                        {/*    <span className={"about-experience-technology"}>Mobile & GUI Engineering</span>*/}
                        {/*    <span className={"about-experience-technology"}>.NET Technologies</span>*/}
                        {/*    <span className={"about-experience-technology"}>Cloud Operations</span>*/}
                        {/*    <span className={"about-experience-technology"}>Distributed Systems</span>*/}
                        {/*    <span className={"about-experience-technology"}>Software Engineering Practice</span>*/}

                        {/*    <span className={"about-experience-technology"}>Business</span>*/}
                        {/*    <span className={"about-experience-technology"}>Project & Quality Management</span>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <div className={"about-experience-section"}>
                <div className={"about-experience-heading"}>Experience</div>
                <div className={"about-experience-item"}>
                    <div className={"about-experience-date"}>May 2024 - Jun 2024</div>
                    <div>
                        <h2 className={"about-experience-title"}>BZZ Bildungszentrum Zürichsee • <i>Substitute Teacher for JavaScript</i></h2>
                        <p className={"about-experience-description"}>
                            I had the privilege of instructing students in the development of interactive websites using JavaScript.
                            The course covered essential JavaScript fundamentals, including variables, functions, loops, and handling REST APIs.
                            The students had to hand in a graded project where they would use PocketBase as a backend and create a frontend using JavaScript.
                        </p>
                        <div className={"about-experience-technologies"}>
                            <span className={"about-experience-technology"}>JavaScript</span>
                            <span className={"about-experience-technology"}>REST API</span>
                            <span className={"about-experience-technology"}>PocketBase</span>
                            <span className={"about-experience-technology"}>Leadership</span>
                            <span className={"about-experience-technology"}>Teaching</span>
                        </div>
                    </div>
                </div>
                <div className={"about-experience-item"}>
                    <div className={"about-experience-date"}>Apr 2021 - Mar 2024</div>
                    <div>
                        <h2 className={"about-experience-title"}>Crowdhouse AG • <i>System Engineer</i></h2>
                        <p className={"about-experience-description"}>
                            Even though I was working as a System Engineer, the role extended to software
                            engineering
                            aspects, where I automated tedious tasks using JavaScript and wrote Shell scripts to
                            manage
                            250+ devices.
                            <br/>Identity management with Jumpcloud, Google Workspace, and Microsoft Azure,
                            integrating
                            SSO across the board. Optimizing and extending the network architecture, server
                            virtualization, and system migration. Enhancing and managing the tech stack for the
                            internal
                            operation of the company.
                            Thanks to the size of the company, I was able to bring my input, make critical
                            decisions,
                            and create processes that did not exist before.
                            Enabling lots of automation by setting up MDM and true zero-touch for Mac deployment for
                            smooth on-site and remote onboarding.
                        </p>
                        <div className={"about-experience-technologies"}>
                            <span className={"about-experience-technology"}>Automation</span>
                            <span className={"about-experience-technology"}>Bash/Shell</span>
                            <span className={"about-experience-technology"}>JavaScript</span>
                            <span className={"about-experience-technology"}>Zero-Touch</span>
                            <span className={"about-experience-technology"}>MDM</span>
                            <span className={"about-experience-technology"}>Identity Management</span>
                            <span className={"about-experience-technology"}>SSO</span>
                            <span className={"about-experience-technology"}>Network Engineering</span>
                            <span className={"about-experience-technology"}>Server Engineering</span>
                            <span className={"about-experience-technology"}>Project Lead</span>
                        </div>
                    </div>
                </div>
                <div className={"about-experience-item"}>
                    <div className={"about-experience-date"}>Aug 2015 - Mar 2021</div>
                    <div>
                        <h2 className={"about-experience-title"}>Hotelplan Management AG • <i>Apprenticeship &
                            Junior IT
                            Supporter</i></h2>
                        <p className={"about-experience-description"}>
                            Being sent from department to department during my "Betriebsinformatik" apprenticeship,
                            I
                            obtained a wide range
                            of knowledge, such as in-depth frontend engineering and object-oriented programming, but
                            also Windows server engineering and network engineering.
                            "Betriebsinformatik" is a Swiss apprenticeship, combining IT system and software
                            engineering.
                            After my apprenticeship, I obtained a role as a Junior IT Supporter ranging from 2nd to
                            3rd level, where I would also help significantly with IT operations.
                            One noteworthy project was the migration of the company's domain controller to a new server
                            environment with no
                            downtime.
                            Another was the crafting of watchdog scripts: A state machine which refers to
                            log files of other watchdog scripts to ensure the monitoring servers were checking the
                            system health of each other.
                        </p>
                        <div className={"about-experience-technologies"}>
                            <span className={"about-experience-technology"}>Frontend Engineering</span>
                            <span className={"about-experience-technology"}>Php</span>
                            <span className={"about-experience-technology"}>Twig</span>
                            <span className={"about-experience-technology"}>Databases</span>
                            <span className={"about-experience-technology"}>Bitbucket</span>
                            <span className={"about-experience-technology"}>Jira</span>
                            <span className={"about-experience-technology"}>Automation</span>
                            <span className={"about-experience-technology"}>PowerShell</span>
                            <span className={"about-experience-technology"}>System Monitoring</span>
                            <span className={"about-experience-technology"}>Linux</span>
                            <span className={"about-experience-technology"}>VMware</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;


