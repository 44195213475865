import React from "react";

const creations = [
    {
        name: "Gallery",
        languages: ["Next.js", "React", "Drizzle", "Clerk"],
        link: "https://github.com/thomasruegg/gallery",
        img: "nextjs-gallery.jpg"
    },
    {
        name: "Train Delay Visualizer",
        languages: ["Prisma", "React", "TypeScript"],
        link: "https://github.com/TrainDelayVisualizer/TrainDelayVisualizer",
        img: "train-delay-visualizer.png"
    },
    {
        name: "Journaling App",
        languages: ["Android SDK", "Kotlin", "MVVM", "RoomDB"],
        link: "https://github.com/thomasruegg/dayournal",
        img: "journal.jpg"
    },
    // {
    //     name: "Bank of Rapperswil",
    //     languages: ["React", "Angular", "TypeScript"],
    //     link: "github.com",
    //     img: "mac-essentials.jpg"
    // },
    {
        name: "Clean Code Summary",
        languages: ["Book by Robert C. Martin"],
        link: "https://github.com/thomasruegg/clean-code-summary",
        img: "clean-code-book.png"
    },
    {
        name: "macOS Essentials",
        languages: ["Brew", "Zsh"],
        link: "https://github.com/thomasruegg/macos-essentials",
        img: "mac-essentials.jpg"
    },
    {
        name: "Convolutional Neural Networks",
        languages: ["Machine Learning", "Python", "TensorFlow", "Kaggle", "Conda"],
        link: "https://github.com/thomasruegg/cnn-image-classification",
        img: "convolutional-neural-networks.jpeg"
    },
    {
        name: "Stable Diffusion Bot",
        languages: ["Python", "VueJS", "Stable Diffusion"],
        link: "not-ready-yet",
        img: "dogger.jpg"
    },
    {
        name: "Parallelized Matrix Multiplication",
        languages: ["NVIDIA Cuda", "C++"],
        link: "https://github.com/thomasruegg/cuda-parallel-matrix-multiplication",
        img: "matrix-multiplication-1.png"
    },
    {
        name: "Hacking Android",
        languages: ["SMALI", "Kali Linux"],
        link: "not-ready-yet",
        img: "android-wallpaper.jpg"
    },
    {
        name: "Skylar Chatbot",
        languages: ["Python", "Dialogflow"],
        link: "not-ready-yet",
        img: "google-cloud.jpg"
    },
    {
        name: "Extended Rock Paper Scissors Game",
        languages: ["HTML", "CSS", "JavaScript"],
        link: "not-ready-yet",
        img: "rock-paper-scissors.png"
    }
]


function Work() {
    return (
        <section id={"work"} className={"projects-container"}>
            <div className={"projects-headline"}>Work</div>
            <div className={"projects-wrapper"}>
                {creations.map(creation => (
                    <a className={"projects-card"} href={creation.link} target={"_blank"}
                        style={{ backgroundPosition: "center", backgroundSize: "100%", backgroundClip: "fill", backgroundImage: `url(${require(`../images/creations-section/${creation.img}`)})` }}>
                        <div className={"projects-card-gradient"}></div>
                        <div className={"projects-card-links"}></div>
                        <h3 className={"projects-card-name"}>{creation.name}</h3>
                        <div className={"projects-card-languages"}>
                            {creation.languages.map(language => (
                                <span>{language}</span>
                            ))}
                        </div>
                    </a>
                ))}
            </div>
        </section>
    )
}

export default Work;